import {
  ChangeEvent,
  FormEvent,
  ReactElement,
  useEffect,
  useState
} from 'react'

import { featureFlagsEventName } from 'const/flags/globals'
import { getAppSessionItem, setAppSessionItem } from 'helpers/appSessionItem'
import { featureFlagMocking, mockFlags } from 'const/session'
import defaultFlags from 'const/flags/nonprod/flags.json'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons'
import { CheckBoxWrap } from 'ui/App/AppWrapper.styles'

import { Body } from 'syf-component-library/ui/typography'
import { Button, Radio } from 'syf-component-library/ui/atoms'
import Tooltip from 'syf-component-library/ui/atoms/Tooltip'
import { RadioGroup } from 'syf-component-library/ui/patterns'

import { useSettings } from 'hooks'
import {
  ButtonContainer,
  CheckboxContainer,
  MockInputContainer,
  MockInputWrap,
  ToolTipIconContainer
} from './ToggleMockFlags.styles'

/**
 * Toggles on or off mocking for feature flags. If toggled on, it should render a form for
 * mocking the System Banner Notification which, when submitted, will trigger the override
 * handler in useUpdateFeatureFlags.
 *
 * @returns {ReactElement}
 */
const ToggleMockFlags = (): ReactElement => {
  const isMockFeatureFlagsEnabled =
    getAppSessionItem(featureFlagMocking) === 'true'

  const removeMockFlagsFromStore = () => sessionStorage.removeItem(mockFlags)

  const { isMockDataEnabled } = useSettings()
  const [checked, setChecked] = useState(!!isMockFeatureFlagsEnabled)
  const [isDisabled, setIsDisabled] = useState(!isMockDataEnabled)

  const [formData, setFormData] = useState({
    showAlertBanner: true,
    alertMessage: '',
    alertTitle: '',
    alertSeverity: 'medium',
    alertLink: '',
    alertLinkText: ''
  })

  const populateFlagStorage = () => {
    setAppSessionItem(featureFlagMocking, 'true')
    setAppSessionItem(mockFlags, JSON.stringify(defaultFlags))
  }

  const toggleSessionStoreMockFlags = () => {
    isMockFeatureFlagsEnabled
      ? setAppSessionItem(featureFlagMocking, 'false')
      : populateFlagStorage()
  }

  const handleClick = () => {
    setChecked((prev) => !prev)
    toggleSessionStoreMockFlags()
  }

  const updateAlertBanner = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const mockedFlagsInfo = JSON.parse(getAppSessionItem(mockFlags))
    mockedFlagsInfo.flags.alertBanner = formData
    setAppSessionItem(mockFlags, JSON.stringify(mockedFlagsInfo))
    setAppSessionItem(featureFlagMocking, 'true')
    // Avoid another network call by directly notifying the app to update the flags from the ones in session store
    window.dispatchEvent(new Event(featureFlagsEventName))
  }

  useEffect(() => {
    // If Mock Data is turned off, shut off feature flag mocking, clear mock flags from store, uncheck the checkbox
    if (!isMockDataEnabled) {
      setChecked(false)
      setIsDisabled(true)
      setAppSessionItem(featureFlagMocking, 'false')
      removeMockFlagsFromStore()
    } else {
      setIsDisabled(false)
    }
  }, [isMockDataEnabled])

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  const handleSelectChange = (value: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      alertSeverity: value
    }))
  }

  const severities = ['medium', 'high', 'rectified', 'info', 'offline']

  const toRadios = (current: string) => {
    const label = current.charAt(0).toUpperCase() + current.slice(1)
    return (
      <Radio
        className="radio-wrapper"
        key={`ToggleMockFlags-radio-${current}`}
        id={`ToggleMockFlags-radio-${current}`}
        inputClassName="radio-input"
        name={current}
        value={current}
      >
        {label}
      </Radio>
    )
  }

  return (
    <>
      <CheckboxContainer>
        <CheckBoxWrap
          isChecked={checked}
          isDisabled={isDisabled}
          onChange={handleClick}
          icon={checked && <FontAwesomeIcon icon={faCheck} />}
        >
          Mock Feature Flags
        </CheckBoxWrap>

        {isDisabled && (
          <Tooltip
            content="Mock Data must be enabled"
            placement="top-start"
            isInteractive
          >
            <ToolTipIconContainer>
              <FontAwesomeIcon icon={faCircleQuestion} size="lg" />
            </ToolTipIconContainer>
          </Tooltip>
        )}
      </CheckboxContainer>
      <br />

      {checked && (
        <>
          <Body weight="bold">System Banner Notification (Alert Banner)</Body>
          <br />

          <form onSubmit={updateAlertBanner}>
            <MockInputContainer>
              Alert title:
              <MockInputWrap
                required
                id="title"
                data-testid="title"
                name="alertTitle"
                value={formData.alertTitle}
                onChange={handleOnChange}
              />
              <Tooltip
                content="Title for the System Banner Notification (required)"
                placement="top-start"
                isInteractive
              >
                <ToolTipIconContainer>
                  <FontAwesomeIcon icon={faCircleQuestion} size="lg" />
                </ToolTipIconContainer>
              </Tooltip>
            </MockInputContainer>
            <MockInputContainer>
              Alert message:
              <MockInputWrap
                required
                id="title"
                data-testid="title"
                name="alertMessage"
                value={formData.alertMessage}
                onChange={handleOnChange}
              />
              <Tooltip
                content="Message for the System Banner Notification (required)"
                placement="top-start"
                isInteractive
              >
                <ToolTipIconContainer>
                  <FontAwesomeIcon icon={faCircleQuestion} size="lg" />
                </ToolTipIconContainer>
              </Tooltip>
            </MockInputContainer>
            <CheckboxContainer>
              <Body>Alert Severity:</Body>
              <Tooltip
                content="Severity Level for the System Banner Notification (required)"
                placement="top-start"
                isInteractive
              >
                <ToolTipIconContainer>
                  <FontAwesomeIcon icon={faCircleQuestion} size="lg" />
                </ToolTipIconContainer>
              </Tooltip>
            </CheckboxContainer>

            <RadioGroup
              direction="column"
              onChange={handleSelectChange}
              selected={formData.alertSeverity}
            >
              {severities.map((severity) => toRadios(severity))}
            </RadioGroup>
            <br />
            <br />
            <Body>Alert Link (optional)</Body>
            <MockInputContainer>
              Alert link message:
              <MockInputWrap
                id="title"
                data-testid="title"
                name="alertLinkText"
                value={formData.alertLinkText}
                onChange={handleOnChange}
              />
              <Tooltip
                content="Message that the link should display (optional)"
                placement="top-start"
                isInteractive
              >
                <ToolTipIconContainer>
                  <FontAwesomeIcon icon={faCircleQuestion} size="lg" />
                </ToolTipIconContainer>
              </Tooltip>
            </MockInputContainer>
            <MockInputContainer>
              Alert link URL:
              <MockInputWrap
                id="title"
                data-testid="title"
                name="alertLink"
                value={formData.alertLink}
                onChange={handleOnChange}
              />
              <Tooltip
                content="URL for the alert link (optional)"
                placement="top-start"
                isInteractive
              >
                <ToolTipIconContainer>
                  <FontAwesomeIcon icon={faCircleQuestion} size="lg" />
                </ToolTipIconContainer>
              </Tooltip>
            </MockInputContainer>
            <ButtonContainer>
              <Button type="submit" buttonType="secondary">
                Update Mock Alert Banner
              </Button>
            </ButtonContainer>
          </form>
        </>
      )}
    </>
  )
}

export default ToggleMockFlags
