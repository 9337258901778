import { LANGUAGE_OVERRIDE_KEY } from 'strings/lang/lang.constants'
import {
  SupportedLanguage,
  getLanguageFromStorage as getInitialLanguageFromParamsOrStorage
} from 'syf-js-utilities'
import getLanguageInLocalStorage from './getLanguageInLocalStorage'

/**
 * getCurrentLanguage validates whether to use the language sent through the URL
 * or the one stored in the `localStorage`.
 *
 * We prefer the local storage language value when it was manually set, preference which is stored with `language_override` value in `localStorage`.
 *
 * @returns {SupportedLanguage} allowed language string value like `en`
 */
const getCurrentLanguage = (): SupportedLanguage =>
  localStorage.getItem(LANGUAGE_OVERRIDE_KEY) === 'true'
    ? getLanguageInLocalStorage()
    : getInitialLanguageFromParamsOrStorage()

export default getCurrentLanguage
