import styled from 'styled-components'
import { fontSizes, spacing } from 'syf-component-library/defaultTheme'

export const MockInputContainer = styled.div`
  margin: 0.5rem 0;
`

export const CheckboxContainer = styled.div`
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
`

export const MockInputWrap = styled.input`
  padding: 3px;
  margin: 0px ${spacing.small}px;
  width: 50%;
`

export const ToolTipIconContainer = styled.span`
  font-size: ${fontSizes.small}px;
  margin-left: 4px;
`

export const ButtonContainer = styled.div`
  margin-top: 1rem;
`
