/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/no-var-requires */
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'
import App from 'ui/App/App'
import { DEV } from 'const/config'
import { setLocalMockDataEnabled } from 'helpers/localMockDataEnabled'

if (DEV) {
  // force use of js module, mjs module causing compile errors, might get fixed in a future axe version
  import('@axe-core/react').then(({ default: axe }) => {
    axe(React, ReactDOM, 1000)
  })
}

const initApp = async () => {
  const container = document.getElementById('root')
  const root = createRoot(container)
  // Start msw from the app initialization for cypress and non-prod envs
  if (window.Cypress) {
    const { startMSW } = await import('mocks/mswBrowser')
    await startMSW()
    setLocalMockDataEnabled(true)
  }

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )
}

initApp()
