import { ReactElement } from 'react'
import getImageURL from 'helpers/getImageURL'
import { SupportedLanguage } from 'syf-js-utilities'
import { SUPPORTED_LANGUAGES } from 'strings/lang/lang.constants'
import getHeaderLanguageText from 'helpers/getHeaderLanguageText'
import useWindowSize from 'syf-component-library/ui/customHooks/useWindowWidth'
import ScreenReaderOnly from 'ui/atoms/ScreenReaderOnly'
import { i18n } from 'strings/i18n'
import { getLanguageWithCountryCode } from 'helpers/language'
import {
  ContentContainer,
  IconWrapper,
  LanguageButton,
  LanguageButtonText,
  Logo,
  NavContainer
} from './TransparentHeader.styles'

interface Props {
  language: SupportedLanguage
  languageIcon: ReactElement
  handleLanguageClick: () => void
}

const { EN, ES } = SUPPORTED_LANGUAGES

const TransparentHeader = ({
  language,
  languageIcon,
  handleLanguageClick
}: Props): ReactElement => {
  const languageButtonLangAttribute = language === EN ? ES : EN
  const windowWidth = useWindowSize()
  const languageButtonText = getHeaderLanguageText(
    language,
    windowWidth.isLessThanMobile
  )
  return (
    <NavContainer>
      <ContentContainer className="transparentHeader">
        <Logo
          src={getImageURL('syf-logo-white')}
          alt={i18n({ header: 'synchronyLogoImgAlt' })}
        />
        <LanguageButton
          id="language-button"
          ariaLabel={i18n({ transparentHeader: 'languageAriaLabel' })}
          buttonType="text"
          data-reason={languageButtonText}
          data-type="customer menu"
          data-analytics="clicktrackingevent"
          onClick={handleLanguageClick}
        >
          {languageIcon && <IconWrapper>{languageIcon}</IconWrapper>}
          <LanguageButtonText
            lang={getLanguageWithCountryCode(languageButtonLangAttribute)}
          >
            {languageButtonText.toUpperCase()}
          </LanguageButtonText>
          <ScreenReaderOnly
            text={i18n({ transparentHeader: 'languageAriaLabel' })}
          />
        </LanguageButton>
      </ContentContainer>
    </NavContainer>
  )
}

export default TransparentHeader
