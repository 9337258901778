import type { ReactElement } from 'react'
import { useEffect } from 'react'
import getErrorTypeInHTTPDataError from 'helpers/getErrorTypeInHTTPDataError'
import getErrorCaseFromError from 'helpers/getErrorCaseFromError'
import { HOME_PAGE } from 'const/config'
import ROUTES from 'const/routes'
import { useQualtrics, useUser } from 'hooks'
import handleLogout from 'helpers/handleLogout'
import triggerAnalyticsEvent from 'helpers/triggerAnalyticsEvent'
import { analyticsEvents } from 'helpers/analytics/events'
import { IOS_SAFARI_MODULE_IMPORT_ERROR } from 'const/moduleErrors'
import {
  UNIVERSAL_ACTIVATION_AUTH_ERRORS,
  UNIVERSAL_ACTIVATION_TOKEN_ERROR
} from 'const/universalActivation'
import { QUERY_PARAMS } from 'const/queryParams'
import { clearAccessTokenAndStorage } from 'helpers/clearAccessTokenAndStorage'
import isUniversalActivation from 'helpers/authFlows/isUniversalActivation'
import {
  BannerCardContainer,
  BannerCardInnerContainer
} from 'ui/pages/Pages.subcomponents'
import isErrorHandledByCardActions from 'helpers/errors/isErrorHandledByCardActions'
import { IOVATION_AUTH_ERROR_CODE } from 'const/businessErrors'
import LoadingView from '../LoadingView'
import type { ErrorComponentProps } from './ErrorComponent.types'
import PersonalGreeting from '../PersonalGreeting'
import ErrorCard from '../ErrorCard'
import ErrorHeader from '../ErrorHeader/ErrorHeader'

const activationErrorRoute = `${ROUTES.UNIVERSAL_ACTIVATION}/${ROUTES.ERROR}`

const ErrorComponent = ({ error }: ErrorComponentProps): ReactElement => {
  const {
    currentLanguage,
    setErrorExternalNavigate,
    isActivateFlowEnd,
    setIsActivateFlowEnd
  } = useUser()
  const { reloadQualtrics } = useQualtrics()

  const errorCase = getErrorCaseFromError(error)
  const errorType = getErrorTypeInHTTPDataError(errorCase)

  const isUniversalActivationFlow = isUniversalActivation()

  useEffect(() => {
    reloadQualtrics()
  }, [currentLanguage])

  // since error component is outside of app router, redirect is a side effect
  useEffect(() => {
    const shouldRerouteToActivationScreen =
      isUniversalActivationFlow && isErrorHandledByCardActions(errorType)
    if (shouldRerouteToActivationScreen) {
      // to allow rendering error screens from in ActivationErrorAdapter that are not triggered by /activate call
      if (!isActivateFlowEnd) setIsActivateFlowEnd(true)
      // set the error param for activation error route using the business error value
      const activationErrorSearchParams = new URLSearchParams()
      activationErrorSearchParams.append(QUERY_PARAMS.code, errorCase.errorType)
      setErrorExternalNavigate(
        `${activationErrorRoute}?${activationErrorSearchParams.toString()}`
      )
    }
  }, [errorCase, errorType])

  const renderErrorCard = (): ReactElement => {
    const handleClick = () => {
      window.location.replace(HOME_PAGE)
    }

    const handleTryAgainClick = () => {
      setErrorExternalNavigate(ROUTES.UNIVERSAL_ACTIVATION)
    }

    const handleLogoutClick = async () => {
      triggerAnalyticsEvent(analyticsEvents.logoutClick())
      await handleLogout()
    }
    /*
     * As iovation error code from response is now the same for all the flows,
     * we need to check for error card actions only when we are in activation flow
     */
    if (isUniversalActivationFlow && isErrorHandledByCardActions(errorType))
      return null

    switch (errorType) {
      case 'no_active_accounts':
        return (
          <ErrorCard
            image="sleepy-cat"
            bodyTitle="noActiveAccounts"
            bodyContent="noActiveAccountsCustomerService"
            centerButtonText="signOut"
            centerButtonClick={handleLogoutClick}
            currentLanguage={currentLanguage}
          />
        )
      case IOVATION_AUTH_ERROR_CODE: // high risk/iovation denied
        return (
          <ErrorCard
            image="blocked"
            bodyTitle="troubleLoggingIn"
            bodyContent="contactCustomerService"
            currentLanguage={currentLanguage}
          />
        )
      case 'idp.authenticate.account.status.error':
        return (
          <ErrorCard
            image="sleepy-cat"
            bodyTitle="unableToSignInAccountStatus"
            bodyContent="securedAccountCustomerService"
            currentLanguage={currentLanguage}
          />
        )
      case UNIVERSAL_ACTIVATION_TOKEN_ERROR:
        return (
          <ErrorCard
            image="cat"
            bodyTitle="troubleActivating"
            bodyContent="technicalDifficultiesActivation"
            centerButtonText="tryAgain"
            centerButtonClick={handleTryAgainClick}
            currentLanguage={currentLanguage}
            errorType={UNIVERSAL_ACTIVATION_TOKEN_ERROR}
          />
        )
      case 'idp.error.clientid':
      case 'idp.interaction.not.found':
      case 'converting.auth_code':
      case 'token.invalid':
      case 'invalid_session':
      case 'Error finishing authorization, no auth code for tokenizing':
        return (
          <ErrorCard
            image="cat"
            bodyTitle="troubleLoggingIn"
            bodyContent="technicalDifficulties"
            centerButtonText="backToSignIn"
            centerButtonClick={handleClick}
            currentLanguage={currentLanguage}
          />
        )
      case IOS_SAFARI_MODULE_IMPORT_ERROR:
        return <LoadingView showText={false} />
      case UNIVERSAL_ACTIVATION_AUTH_ERRORS.INVALID_CLIENT:
        return (
          <ErrorCard
            image="cat"
            bodyTitle="troubleFindingVendor"
            bodyContent="checkUrlOnCard"
            centerButtonText="tryAgain"
            centerButtonClick={handleTryAgainClick}
            currentLanguage={currentLanguage}
          />
        )
      case '400': // bad request
      case '401': // unauthorized
      case '403': // forbidden
      case '404': // not found
      case '405': // method not allowed
      case '415': // unsupported media type
      case '422': // unprocessable entity
      case '500': // internal server error
      case '502': // bad gateway
      case '503': // service unavailable
      case '504': // gateway timeout
        // removing access token in case something breaks in these scenarios
        clearAccessTokenAndStorage()
        if (isUniversalActivationFlow) {
          return (
            <ErrorCard
              image="cat"
              bodyTitle="generalActivationTechnicalDifficulties"
              bodyContent="tryAgainLater"
              currentLanguage={currentLanguage}
            />
          )
        }
        return (
          <ErrorCard
            image="cat"
            bodyTitle="header"
            bodyContent="technicalDifficulties"
            currentLanguage={currentLanguage}
          />
        )

      default: // unknown errors
        return (
          <ErrorCard
            image="cat"
            bodyTitle="header"
            bodyContent="technicalDifficulties"
            currentLanguage={currentLanguage}
          />
        )
    }
  }

  const hideGreetingForError = [
    IOS_SAFARI_MODULE_IMPORT_ERROR,
    'idp.authenticate.account.status.error'
  ].includes(errorCase.errorType)

  const hideGreeting = hideGreetingForError || isUniversalActivationFlow

  const showErrorHeader =
    [
      UNIVERSAL_ACTIVATION_TOKEN_ERROR,
      '500',
      UNIVERSAL_ACTIVATION_AUTH_ERRORS.INVALID_CLIENT
    ].includes(errorCase.errorType) || !isUniversalActivationFlow

  return (
    <>
      {showErrorHeader && <ErrorHeader />}
      {hideGreeting ? null : <PersonalGreeting />}
      <BannerCardContainer>
        <BannerCardInnerContainer aria-label="error page">
          {renderErrorCard()}
        </BannerCardInnerContainer>
      </BannerCardContainer>
    </>
  )
}

export default ErrorComponent
