import type { ReactElement } from 'react'
import routes from 'const/routes'
import {
  CARD_AUTH_FLOW,
  PAYLATER_AUTH_FLOW,
  SECURED_INSTALLMENTS_FLOW,
  UNIVERSAL_ACTIVATION_FLOW
} from 'const/constants'
import Redirect from 'ui/atoms/Redirect'
import prependSlash from 'helpers/prependSlash'
import { getSessionAuthFlow } from 'helpers/authFlows'

/**
 * Acts as a redirect router to direct the user to a specific
 * signin page based on the session storage authflow value.
 * Useful since the auth server just redirects to the bare /quickaccess/signin
 * page and we need to remember and get users back to the specific signin page
 * they originally entered from for their account type.
 * @returns a router redirect that passes all search params to the specific signin page
 */
const SignInRouter = (): ReactElement => {
  const getSignInBeforeRedirect = (currentAuthFlow: string) => {
    switch (currentAuthFlow) {
      case PAYLATER_AUTH_FLOW:
        return routes.LOGIN_LOAN
      case CARD_AUTH_FLOW:
        return routes.LOGIN_REVOLVING
      case UNIVERSAL_ACTIVATION_FLOW:
        return routes.UNIVERSAL_ACTIVATION
      case SECURED_INSTALLMENTS_FLOW:
        return routes.LOGIN_SECURED_INSTALLMENTS
      default:
        return routes.LOGIN_LOAN
    }
  }

  const sessionAuthFlow = getSessionAuthFlow()
  const selectedRoute = getSignInBeforeRedirect(sessionAuthFlow)

  return <Redirect to={prependSlash(selectedRoute)} />
}

export default SignInRouter
