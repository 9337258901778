import styled from 'styled-components'
import { colors } from 'syf-component-library/defaultTheme'

import MessageBox from 'syf-component-library/ui/patterns/MessageBox'

export const NoAccountMessageBox = styled(MessageBox)`
  /** border and icon should match colors as well */
  border: 2px solid ${colors.darkRed};
  & svg {
    color: ${colors.darkRed};
  }
`
