import redirectToUrl from 'syf-js-utilities/helpers/redirectToUrl'
import {
  SYF_FIND_ACCOUNT_URL,
  UNIVERSAL_ACTIVATION_FLOW
} from 'const/constants'
import { useSessionClient } from 'hooks/useSessionClient'
import { useLayoutEffect } from 'react'
import type { QueryParamsCheckProps } from './QueryParamsCheck.types'

/**
 * Component to check if the required query params are present in the url
 * for a certain authFlow
 *
 * @param {queryParamsProps} props - Component props containing:
 * @param {ReactElement} props.children - what to render if all the required query params are present
 * @param {AuthFlowType} props.authFlow - the specific type of authFlow page and flow user will be directed to
 * @returns {ReactElement} - This element children or redirects the user to another page
 */
const QueryParamsCheck = ({ children, authFlow }: QueryParamsCheckProps) => {
  const { getSessionClient, updateSessionClient } = useSessionClient()
  // fallback to last stored value to keep in sync in case query param removed
  const client = getSessionClient()

  useLayoutEffect(() => {
    if (authFlow === UNIVERSAL_ACTIVATION_FLOW) {
      if (!client) {
        redirectToUrl(SYF_FIND_ACCOUNT_URL)
        return
      }
      updateSessionClient(client)
    }
  }, [client, authFlow])

  return children
}

export default QueryParamsCheck
