import { useEffect, useState } from 'react'
import { UseQueryResult } from '@tanstack/react-query'
import type HTTPError from 'syf-js-utilities/typings/HTTPError'

import { sessionIdName } from 'const/session'
import config from 'const/config'
import {
  compressToNewRelicCharacterLimit,
  setNewRelicAttribute,
  setNewRelicPageAction,
  setNewRelicSessionUserId
} from 'helpers/newRelic'
import createPromise from 'helpers/createPromise'
import { setSessionID } from 'helpers/authUtils'
import {
  introspectURL,
  useIntrospectQuery
} from 'services/introspectQuery/introspectQuery'
import type {
  IntrospectQueryData,
  IntrospectQueryProps
} from 'services/introspectQuery/introspectQuery.types'
import useSettings from './useSettings'

interface AuthSessionData {
  introspectQuery: UseQueryResult<IntrospectQueryData, HTTPError>
  introspectPromise: Promise<null>
}

const [introspectPromise, resolver] = createPromise()

/**
 * Custom hook to get the session id via /introspect and save it session storage.
 * Also redirects user to start auth flow if /authorize token is missing.
 * @returns obj - { introspectQuery, introspectPromise }
 * @returns obj - introspectQuery - a react query result obj
 * @returns Promise - introspectPromise - represents whether the introspectQuery has finished
 */
const useAuthSession = (): AuthSessionData => {
  const [, setPromiseFulfilled] = useState(false)
  const { isMockDataEnabled } = useSettings()
  // disable introspect call if mocking enabled until mocking is ready
  const [enableIntrospect, setEnableIntrospect] = useState(!isMockDataEnabled)
  const queryProps: IntrospectQueryProps = {
    config,
    queryOptions: {
      // so only 1 introspect request is made per page load since its a single user session
      staleTime: Infinity,
      enabled: enableIntrospect
    }
  }
  const introspectQuery = useIntrospectQuery(queryProps)
  const { isSuccess } = introspectQuery

  useEffect(() => {
    if (isMockDataEnabled) {
      /*
       * hacky way to wait for introspect override mock handlers
       * to be in effect in msw before making introspect call to prevent flakiness for
       * special situation introspect handlers since it usually happens on load
       * 500ms is just based on trial and error testing on how much delay is needed
       */
      window.setTimeout(() => setEnableIntrospect(true), 500)
    }
  }, [isMockDataEnabled])

  useEffect(() => {
    const { data: introspectData } = introspectQuery
    // ensure we have query data response ready bc this hook runs on any isSuccess updates
    if (!introspectData?.interactionId) {
      return
    }
    resolver()
    setPromiseFulfilled(true)
    // save the session id for entire app availability
    const { interactionId = '' } = introspectData
    setSessionID(interactionId)
    // set new relic tracking script attribute
    setNewRelicAttribute(sessionIdName, interactionId)
    // set the new relic user id value to associate actions with a user
    setNewRelicSessionUserId()
    // log introspect done for tracking user flow
    const truncatedResponse = compressToNewRelicCharacterLimit(
      JSON.stringify(introspectData)
    )
    setNewRelicPageAction(`Done POST ${introspectURL}`, {
      response: truncatedResponse,
      interactionId
    })
  }, [isSuccess])

  return { introspectQuery, introspectPromise }
}

export default useAuthSession
