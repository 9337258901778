import { LanguageObject } from './typings'

const common = {
  creditCardError: 'Ingrese el número completo de la tarjeta de crédito',
  ssnPlaceholder: 'Últimos 4 Dígitos del SSN',
  ssnInvalidError: 'Los últimos 4 dígitos del SSN son requeridos',
  ssnEmptyError: 'Ingrese los últimos 4 dígitos del SSN',
  zipCodePlaceholder: 'Código Postal',
  zipCodeEmptyError: 'Ingrese un código postal',
  zipCodeIncompleteError: 'El código postal debe tener 5 dígitos',
  tryAgainLater: 'Por favor intente nuevamente más tarde.',
  signInBtn: 'Iniciar Sesión',
  or: 'o',
  signInTitle: 'Acceder al préstamo'
}

const es: LanguageObject = {
  labels: {
    accountEndingIn: 'Cuenta que termina en',
    activity: 'Actividad Reciente',
    loading: 'Buscando tu cuenta',
    loanEndingIn: 'Préstamo que termina en'
  },
  errors: {
    littleTrouble:
      'Lo sentimos, estamos teniendo algunas dificultades en este momento.',
    technicalError:
      'Nuestro sitio está teniendo algunas dificultades técnicas. Por favor intente nuevamente más tarde.',
    pageNotFound: 'Parece que no podemos encontrar lo que está buscando.',
    pageNotFoundImgAlt: 'página no encontrada',
    sorryPage: 'Lo sentimos, algo salió mal',
    maintenanceMessage:
      'Lo sentimos, estamos temporalmente no disponibles por mantenimiento.',
    supportText: 'Por favor vuelva a intentar más tarde o visite '
  },
  errorComponent: {
    header: 'Nos Disculpamos',
    technicalDifficulties:
      'Estamos experimentando dificultades técnicas en este momento y no podemos accesar a su cuenta. Por favor, intente más tarde.',
    contactCustomerService:
      'Debido a un problema con su cuenta, no podrá iniciar sesión en este momento. Comuníquese con Servicio al Cliente al (844) 373-4960',
    troubleLoggingIn: 'Estamos teniendo problemas iniciando su sesión',
    noActiveAccounts:
      'No tenemos ninguna cuenta suya que se encuentre activa en estos momentos.',
    noActiveAccountsCustomerService:
      'Si usted considera que esto es un error, comuníquese con atención al cliente al (844) 373-4960.',
    unableToSignInAccountStatus:
      'No se puede iniciar sesión debido al estado de la cuenta',
    securedAccountCustomerService:
      'Llame al Servicio de Atención al Cliente (866) 220-9432 para hablar de su cuenta o efectuar un pago.',
    signOut: 'Cerrar Sesión',
    useButtonBelow:
      'Use el botón que está a continuación para regresar a la página de inicio e iniciar sesión de nuevo.',
    backToSignIn: 'Regresar a Iniciar Sesión',
    signOutSuccessful: 'Cerraste la sesión con éxito',
    seeYouLater: 'Nos vemos a la próxima',
    troubleActivating: 'Estamos teniendo problemas para activar su cuenta',
    technicalDifficultiesActivation:
      'Estamos experimentando dificultades técnicas en este momento y no podemos accesar a su cuenta.',
    troubleFindingVendor:
      'Estamos teniendo problemas para encontrar a ese proveedor',
    checkUrlOnCard:
      'Por favor, verifique si el URL en el adhesivo de la tarjeta es correcto o escanee el código QR con su teléfono.',
    tryAgain: 'Intente nuevamente',
    generalActivationTechnicalDifficulties:
      'Actualmente, estamos experimentando dificultades técnicas',
    tryAgainLater: common.tryAgainLater
  },
  personalGreeting: {
    morning: 'Buenos Días',
    afternoon: 'Buenas Tardes',
    evening: 'Buenas Noches',
    signOut: 'Hasta luego'
  },
  footer: {
    body: 'Sus cuentas de crédito son emitidas por Synchrony Bank. La Política de Privacidad de Synchrony Bank rige el uso sus cuentas de crédito. El uso de este sitio se rige por el uso de la Política de Privacidad de Internet de Synchrony Bank. Es posible que el contenido y los documentos que se muestran pueden no se proporcionen en español.',
    privacyPolicy: 'Política de Privacidad en Línea',
    privacyPolicyLink: 'legal/privacy-policy-sp',
    usageAgreement: 'Acuerdo de uso en Línea',
    usageAgreementLink: 'legal/terms-of-use-sp',
    fraudProtection: 'Protección contra Fraude',
    fraudProtectionLink: 'legal/fraud-protection-sp',
    accessibility: 'Accesibilidad',
    accessibilityLink: 'web-accessibility-statement-sp'
  },

  paylaterSignIn: {
    title: common.signInTitle,
    signInBtn: common.signInBtn,
    manageCardBtn: 'Administre una Tarjeta de Crédito',
    or: common.or,
    mobilePlaceholder: 'Número de celular',
    mobileHelper: 'El mismo número que se usó en su solicitud',
    mobileEmptyError: 'El número de teléfono móvil debe tener 10 dígitos.',
    mobileInvalidError: 'El número de teléfono móvil debe tener 10 dígitos.',
    ssnPlaceholder: common.ssnPlaceholder,
    ssnEmptyError: common.ssnEmptyError,
    ssnInvalidError: common.ssnInvalidError,
    ssnHelpText: 'SSN: Número de Seguro Social',
    authErrorTitle: 'Verifique su información',
    incorrectCredentials:
      'Estamos teniendo problemas para encontrar esa cuenta. Por favor, verifique e ingrese su información nuevamente.  Si cree que se trata de un error, abra una sesión de chat con un representante de atención al cliente.',
    bannerSynchronyBrandName: 'Synchrony Pay Later',
    bannerBody:
      ' ha mejorado la imagen y sensación de la experiencia de servicio de su cuenta con la misma funcionalidad.'
  },
  revolvingCardSignIn: {
    title: 'Acceder a la tarjeta de crédito',
    creditCardPlaceholder: 'Tarjeta de crédito',
    creditCardError: common.creditCardError,
    ssnPlaceholder: 'Los Últimos 4 Dígitos del SSN o ITIN',
    ssnHelpText: 'SSN: Número de Seguro Social, ITIN: Tax ID',
    ssnError: 'Ingrese los últimos 4 dígitos de su SSN o ITIN',
    zipCodePlaceholder: common.zipCodePlaceholder,
    zipError: 'Ingrese su código postal',
    manageALoan: 'Administre un Préstamo',
    or: common.or
  },
  securedInstallmentsSignIn: {
    title: common.signInTitle,
    accountNumberPlaceholder: 'Número de cuenta',
    accountNumberEmptyError: 'Ingrese un número de cuenta',
    accountNumberInvalidError: 'Verifique el número de cuenta',
    incorrectCredentials:
      'Estamos teniendo problemas para encontrar esa cuenta. Por favor, verifique e ingrese su información nuevamente.  Por favor, vuelva a intentarlo o llame a atención al cliente al (866) 220-9432.',
    signInBtn: common.signInBtn,
    ssnPlaceholder: common.ssnPlaceholder,
    ssnEmptyError: common.ssnEmptyError,
    ssnInvalidError: common.ssnInvalidError,
    zipCodePlaceholder: common.zipCodePlaceholder,
    zipCodeEmptyError: common.zipCodeEmptyError,
    zipCodeIncompleteError: common.zipCodeIncompleteError
  },
  universalActivation: {
    title: 'Active su tarjeta',
    subtitle:
      'Solo necesitamos un poco más de información para completar su activación',
    creditCardPlaceholder: 'Número de Tarjeta de Crédito',
    creditCardHelpText: 'Ingrese el número completo de la tarjeta de crédito',
    creditCardEmptyError: common.creditCardError,
    creditCardIncompleteError: 'Debe tener 16 dígitos',
    securityCodePlaceholder: 'Código de seguridad',
    securityCodeHelpText: 'Código de seguridad en la tarjeta',
    securityCodeEmptyError: 'Ingrese el código de seguridad en la tarjeta',
    securityCodeIncompleteError: 'Debe tener 3 dígitos',
    ssnPlaceholder: common.ssnPlaceholder,
    ssnHelpText: 'Ingrese los últimos 4 dígitos del SSN',
    ssnEmptyError: common.ssnEmptyError,
    ssnInvalidError: common.ssnInvalidError,
    zipCodeIncompleteError: common.zipCodeIncompleteError,
    zipCodePlaceholder: common.zipCodePlaceholder,
    zipCodeHelpText: 'Código Postal de 5 dígitos',
    zipCodeEmptyError: common.zipCodeEmptyError,
    continueButton: 'Continuar',
    cancelButton: 'Cancelar',
    incorrectCredentials:
      'Estamos teniendo problemas para encontrar esa cuenta. Por favor, verifique e ingrese su información nuevamente. Si cree que se trata de un error, llame al número de servicio al cliente que figura en el reverso de su tarjeta.'
  },
  inactivityModal: {
    modalTitle: 'La sesión ha expirado',
    promptTitle: '¿Sigue allí?',
    countdownWarning: 'Su sesión en línea expirará en',
    continueButton: 'SIGO AQUÍ',
    logOffButton: 'CERRAR SESIÓN',
    seconds: 'segundos.'
  },
  mfeErrorModal: {
    modalTitle:
      'Lo sentimos, esta funcionalidad no se encuentra disponible por el momento',
    content: common.tryAgainLater
  },
  buttons: {
    signOut: 'Cerrar Sesión',
    close: 'Cerrar',
    back: 'Atrás'
  },
  /**
   * When the user is on an Spanish page, the language button is going to say "English".
   * For people using a screen reader, they need to be able to hear, in English, what the button is supposed to do.
   */
  transparentHeader: {
    languageAriaLabel: 'Change Language To English'
  },
  header: {
    synchronyLogoImgAlt: 'Logo de Synchrony'
  }
}

export default es
