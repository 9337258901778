import {
  CARD_AUTH_FLOW,
  PAYLATER_AUTH_FLOW,
  SECURED_INSTALLMENTS_FLOW,
  UNIVERSAL_ACTIVATION_FLOW
} from 'const/constants'
import type { AuthFlowType } from 'typings/Auth'
import getCountryLanguage from 'helpers/getCountryLanguage'
import {
  ANALYTICS_API_ERROR_MESSAGE,
  TROUBLE_ACTIVATING_ACCOUNT,
  UNIVERSAL_ACTIVATION_TOKEN_ERROR
} from 'const/universalActivation'
import { ACTIVATION_ANALYTICS_PROPS } from 'const/analytics'
import { getSessionAuthFlow } from 'helpers/authFlows'
import { DefaultAnalyticsObject, PAGE_INFO_PROPS } from './analytics.types'
import { setAnalyticsConfigDefaultProp, setSFDDLprop } from './SFDDLHelpers'

declare global {
  interface Window {
    _SFDDL?: {
      pageInfo: Record<string, string>
    }
  }
}

/**
 * This function delete certain attributes that get set in the SFDDL object from other analytics events
 * Since these attributes are not part of the default SFDDL once they are set up they remain until manually deleted
 */

export const cleanDashboardAnalytics = (): void => {
  delete window._SFDDL.pageInfo.numberofaccounts
}

/** string used to identify events for secured installments auth flow */

export const securedInstallmentsText = 'secured-installments'

/**
 *
 * @param authFlow current authentication flow the user is in
 * @param totalAccounts number of accounts the user has
 * @returns the pageKind to be used for the analytics event
 */

export const getPageKindForDashboard = (
  authFlow: AuthFlowType,
  totalAccounts?: number
) => {
  switch (authFlow) {
    case SECURED_INSTALLMENTS_FLOW:
      return securedInstallmentsText
    case PAYLATER_AUTH_FLOW:
    case CARD_AUTH_FLOW:
    case UNIVERSAL_ACTIVATION_FLOW:
    default:
      return totalAccounts > 1 ? 'multi-loan-account' : 'single-loan-account'
  }
}

/**
 * Whenever an MFE is opened we need to set the default values for ClientName and Product
 * so that subsequent analytics event inside the MFE can have these values.
 */

export const setOpenMFEAnalyticsDefaults = (
  ClientName: string,
  Product: string,
  totalAccounts: number
): void => {
  const pageKind = getPageKindForDashboard(getSessionAuthFlow(), totalAccounts)
  setAnalyticsConfigDefaultProp(PAGE_INFO_PROPS.ClientName, ClientName)
  setAnalyticsConfigDefaultProp(PAGE_INFO_PROPS.Product, Product)
  setAnalyticsConfigDefaultProp(PAGE_INFO_PROPS.PageKind, pageKind)
  if (window._SFDDL?.pageInfo) {
    setSFDDLprop(PAGE_INFO_PROPS.ClientName, ClientName)
    setSFDDLprop(PAGE_INFO_PROPS.Product, Product)
    setSFDDLprop(PAGE_INFO_PROPS.PageKind, pageKind)
  }
}

/**
 * Gets the appropriate PageSubFunction (and potentially MessageType) for a specific UA errorType
 * see ErrorComponent for the different error types
 * @param errorType string
 * @returns string
 */
const getPageSubfunctionForUAError = (errorType: string) => {
  if (errorType === UNIVERSAL_ACTIVATION_TOKEN_ERROR) {
    return TROUBLE_ACTIVATING_ACCOUNT
  }

  return ANALYTICS_API_ERROR_MESSAGE
}

/**
 *
 * @param errorMsg message the user is seeing on the screen
 * @param authFlow current authentication flow the user is in
 * @returns the object to be used for the analytics event
 */

export const getAnalyticsObjectForError = (
  bodyTitleText: string,
  bodyContentText: string,
  authFlow: AuthFlowType,
  errorType = ''
) => {
  const analyticsObject: DefaultAnalyticsObject = {
    ClientName: 'generic',
    Country_Language: getCountryLanguage(),
    PageKind: 'login',
    pageName: 'error',
    ErrorMessage: `${bodyTitleText}. ${bodyContentText}`
  }
  // to change for a switch if more cases are required later
  if (authFlow === SECURED_INSTALLMENTS_FLOW) {
    analyticsObject.PageSubFunction = securedInstallmentsText
    analyticsObject.MessageType = `${securedInstallmentsText} error`
  }
  if (authFlow === UNIVERSAL_ACTIVATION_FLOW) {
    const errorTypeValue = getPageSubfunctionForUAError(errorType)
    analyticsObject.PageSubFunction = errorTypeValue
    analyticsObject.MessageType = errorTypeValue
    analyticsObject.PageKind = ACTIVATION_ANALYTICS_PROPS.ACTIVATION_PAGE_KIND
    analyticsObject.PageFunction =
      ACTIVATION_ANALYTICS_PROPS.ACTIVATION_PAGE_FUNCTION
    analyticsObject.ErrorMessage = bodyTitleText
    analyticsObject.Message = bodyContentText
  }
  return analyticsObject
}

/**
 *
 * @param authFlow current authentication flow the user is in
 * @returns the sub function to be used in the analytics event
 */

export const getPageSubfunction = (authFlow: AuthFlowType) => {
  if (authFlow === SECURED_INSTALLMENTS_FLOW) {
    return securedInstallmentsText
  }
  return ''
}
