import { useEffect } from 'react'
import config from 'const/config'
import useFlagsQuery from 'services/flagsQuery/flagsQuery'
import {
  featureFlagsEventName,
  featureFlagsFetchErrorKey,
  featureFlagsKey
} from 'const/flags/globals'
import { featureFlagMocking, mockFlags } from 'const/session'
import { setFeatureFlagsInStore } from 'helpers/featureFlags'
import { getAppSessionItem } from 'helpers/appSessionItem'
import useSettings from '../useSettings'

/**
 * Checks if the initial feature flag json fetch ran into an error
 * by looking for a fetch error in local storage or if cached flags are missing in local storage.
 * If so, trigger a a refetch to grab the feature flags json and set the result into local storage.
 * Also checks if feature flag mocking is enabled. If so, it replaces the flags in local storage with the mock feature flags object.
 * @returns void
 */
const useUpdateFeatureFlags = (): void => {
  // only need to refetch if the initial fetch ran into an error
  const isInitialFlagFetchError =
    localStorage.getItem(featureFlagsFetchErrorKey) === 'true'
  const { isMockDataEnabled } = useSettings()
  const isMockingFlagFetch =
    getAppSessionItem(featureFlagMocking) === 'true' && isMockDataEnabled

  const mockFlagsData = getAppSessionItem(mockFlags)

  const isFeatureFlagsStored = localStorage.getItem(featureFlagsKey)

  const { data, isSuccess } = useFlagsQuery({
    config,
    queryOptions: {
      enabled:
        isInitialFlagFetchError ||
        !isFeatureFlagsStored ||
        !isMockingFlagFetch ||
        !isMockDataEnabled,
      // only need to fetch it once
      staleTime: Infinity
    }
  })

  useEffect(() => {
    if (isMockingFlagFetch || (isSuccess && data)) {
      // cache the feature flags values in local storage for fast future checks
      setFeatureFlagsInStore(
        isMockingFlagFetch ? JSON.parse(mockFlagsData) : data
      )
      // notifies app listeners to do a live update of their cached feature flag data
      window.dispatchEvent(new Event(featureFlagsEventName))
      // so we don't attempt another flag refetch after this one succeeded
      localStorage.removeItem(featureFlagsFetchErrorKey)
    }
  }, [isSuccess, isMockingFlagFetch, mockFlagsData, isMockDataEnabled])
}

export default useUpdateFeatureFlags
