import { useEffect } from 'react'
import type { ReactElement } from 'react'
import getImageURL from 'helpers/getImageURL'
import { i18n } from 'strings/i18n'
import triggerAnalyticsEvent from 'helpers/triggerAnalyticsEvent'
import { analyticsEvents } from 'helpers/analytics/events'
import en from 'strings/lang/en'
import BannerCard from 'syf-component-library/ui/patterns/BannerCard'
import { getSessionAuthFlow } from 'helpers/authFlows'
import type { ErrorCardProps } from './ErrorCard.types'
import { BodyText, BodyTitle } from './ErrorCard.styles'

const ErrorCard = ({
  image,
  bodyTitle,
  bodyContent,
  centerButtonText,
  centerButtonClick,
  errorType = ''
}: ErrorCardProps): ReactElement => {
  useEffect(() => {
    const bodyTitleText = en.errorComponent[bodyTitle]
    const bodyContentText = en.errorComponent[bodyContent]
    triggerAnalyticsEvent(
      analyticsEvents.error(
        bodyTitleText,
        bodyContentText,
        getSessionAuthFlow(),
        errorType
      )
    )
  }, [])

  const primaryButton = centerButtonText
    ? {
        onClick: centerButtonClick,
        text: i18n({ errorComponent: centerButtonText })
      }
    : null

  return (
    <BannerCard
      imageSrc={getImageURL(image)}
      bodyTitle={<BodyTitle>{i18n({ errorComponent: bodyTitle })}</BodyTitle>}
      bodyDescription={
        <BodyText>{i18n({ errorComponent: bodyContent })}</BodyText>
      }
      primaryButton={primaryButton}
    />
  )
}

export default ErrorCard
