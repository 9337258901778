import type { ReactElement } from 'react'
import translations from 'strings/lang'
import type { AnyObject } from 'syf-js-utilities/typings/AnyObject'
import { getCurrentLanguage } from 'helpers/language'
import type { LanguageObject, StringObject } from './lang/typings'

/**
 * This finds a string from <language>.json, then replaces its slots with values, returning a fully formatted string for the UI.
 * @param {Object} lang Object that has key of the string from <language>.json
 * @param {Object} data Values of slots to replace.  Key is slot, value is replacement.
 */
export const i18n = (lang: StringObject, data?: AnyObject): string => {
  const language = getCurrentLanguage()
  const strings = translations[language]
  const messageKey = Object.keys(lang)[0] as keyof LanguageObject
  const message = strings[messageKey][lang[messageKey] as string]

  if (typeof message === 'string' && data) {
    let result = message
    const args = Object.keys(data)
    args.forEach((arg: string) => {
      result = result.replace(`%${arg}%`, data[arg])
    })
    return result
  }

  return message
}

export const i18nElement = (
  lang: StringObject,
  data?: AnyObject
): ReactElement => {
  const language = getCurrentLanguage()
  const strings = translations[language]
  const messageKey = Object.keys(lang)[0] as keyof LanguageObject
  const message = strings[messageKey][lang[messageKey] as string]
  const element = message(data)

  if (typeof message !== 'string') {
    return element
  }
}
