import { QUERY_PARAMS } from 'const/queryParams'
import { UserContextProps } from 'context/userContext'
import { getAppSessionItem, setAppSessionItem } from 'helpers/appSessionItem'
import useUser from 'hooks/useUser'
import getQueryParam from 'syf-js-utilities/formats/getQueryParam'

interface SessionClient {
  // the client id value saved ie paysol, lowes, etc
  getSessionClient: () => UserContextProps['client']
  // setter to update the client id value currently saved
  updateSessionClient: (string) => void
}

/**
 * Grabs the client value that comes from the url query param,
 * but with fallbacks to the session and context value. Grabbing
 * from the url and/or the context also guards against session
 * storage not working for some Safari 18 mobile users.
 * @returns {SessionClient} sessionclient - object containing
 * the client id string, the setter fn for the client value
 */
const useSessionClient = (): SessionClient => {
  const { client: contextCachedClient, setClient } = useUser()

  const getSessionClient = () =>
    getQueryParam(QUERY_PARAMS.client) ??
    // fallback for session is || in case empty string returned
    (getAppSessionItem(QUERY_PARAMS.client) || contextCachedClient)

  const updateSessionClient = (client: string) => {
    setAppSessionItem(QUERY_PARAMS.client, client)
    // store in context as a backup for session store
    setClient(client)
  }

  return {
    getSessionClient,
    updateSessionClient
  }
}

export default useSessionClient
